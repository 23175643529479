window.addEventListener('heyflow-screen-view', (event) => {
    // console.log('heyflow screen view:', event.detail);
});

window.addEventListener('heyflow-submit', (event) => {
    // console.log('heyflow submit:', event.detail);
});

function getIp() { 

  var xhr = new XMLHttpRequest(); 

    xhr.open('GET', 'https://ipinfo.io/ip', false); 

    xhr.send(); 

    return xhr.responseText 

} 

  

const inputIp = document.createElement('input'); 

inputIp.type = 'hidden'; 

inputIp.name = 'ipAddress'; 

inputIp.id = 'ipAddress'; 

inputIp.setAttribute('data-label', 'ipAddress'); 

inputIp.value = getIp(); 

inputIp.style = "visibility:hidden;" 

  

const inputUserAgent = document.createElement('input'); 

inputUserAgent.type = 'hidden'; 

inputUserAgent.name = 'useragent'; 

inputUserAgent.id = 'useragent'; 

inputUserAgent.setAttribute('data-label', 'useragent'); 

inputUserAgent.value = navigator.userAgent; 

inputUserAgent.style = "visibility:hidden;" 

  

const inputTCPA = document.createElement('input'); 

inputTCPA.type = 'hidden'; 

inputTCPA.name = 'tcpa'; 

inputTCPA.id = 'tcpa'; 

inputTCPA.setAttribute('data-label', 'tcpa'); 

inputTCPA.value = "By clicking the button above, I authorize up to four home improvement service providers to contact me with offers and other info, including possibly using automated technology, recorded and sms messages, at the number I entered above. Consent is not based on a condition of purchase. Message/data rates apply."; 

inputTCPA.style = "visibility:hidden;" 

  

document.addEventListener('DOMContentLoaded', () => { 

    const form = document.getElementsByTagName('form')[0]; 

    form.appendChild(inputIp); 

    form.appendChild(inputUserAgent); 

    form.appendChild(inputTCPA); 

}); 